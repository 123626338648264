import * as React from 'react';
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";
import { ActivityLabels } from './components/labels-strip/labels-strip'
import { JourneyTimes } from "./components/journey-times/journey-times"
import { mapIcons, mapIconsSelected } from './components/map/mapIcons'
import { SocialLinks } from './components/social-links/social-links'

import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet'
import './activity-detail-page.css';
import './header-bar.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { ReactComponent as CloseButton } from '../assets/icons/close-button.svg';
import ReactMarkdown from 'react-markdown'


import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import ContactlessIcon from '@mui/icons-material/Contactless';
import FireplaceOutlinedIcon from '@mui/icons-material/FireplaceOutlined';
import { InstagramEmbed } from 'react-social-media-embed';
import { TikTokEmbed } from 'react-social-media-embed';

// Icons for beyonk bits
import { ReactComponent as RainCoat } from '../assets/icons/beyonk-reqs/icons8-raincoat.svg';
import { ReactComponent as Beanie } from '../assets/icons/beyonk-reqs/icons8-beanie.svg';
import { ReactComponent as Carabiner } from '../assets/icons/beyonk-reqs/icons8-carabiner.svg';
import { ReactComponent as PublicTransportation } from '../assets/icons/beyonk-reqs/icons8-public-transportation.svg';
import { ReactComponent as Restaurant } from '../assets/icons/beyonk-reqs/icons8-restaurant.svg';
import { ReactComponent as SleepingBag } from '../assets/icons/beyonk-reqs/icons8-sleeping-bag.svg';
import { ReactComponent as SportBottle } from '../assets/icons/beyonk-reqs/icons8-sport-bottle.svg';
import { ReactComponent as WinterBoots } from '../assets/icons/beyonk-reqs/icons8-winter-boots.svg';




import analytics from "./../analytics"
import DoneIcon from '@mui/icons-material/Done';
export const ActivityDetailPage = (props) => {

    const { activityId } = useParams();
    const [activtyDetail, setActivtyDetail] = React.useState(null);
    const [activtyBeyonkExtraInfo, setActivtyBeyonkExtraInfo] = React.useState(null);
    const [isLoadedDetail, setIsLoadedDetail] = React.useState(false)
    const [errorFetchingDetail, setErrorFetchingDetail] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState()
    const location = useLocation()

    const [isLightboxOpen, setLightboxIsOpen] = React.useState(false);
    const [lightboxOpenSlideIndex, setLightboxOpenSlideIndex] = React.useState(null);
    const [govBathingWaterProfile, setGovBathingWaterProfile] = React.useState(null);
    const [govWaterQaulityDetail, setGovWaterQaulityDetail] = React.useState(null);

    // We re-use some props from search if linked from there. Eg travel times that are only relevant in context of the trip or search
    var namePassedIn = null
    var travelTimePassedIn = null
    var travelTimeLabelPassedIn = null
    if (location.state != null) {
        if (location.state.hasOwnProperty('name')) {
            namePassedIn = location.state.name
        }
        if (location.state.hasOwnProperty('travelTime')) {
            travelTimePassedIn = location.state.travelTime
        }
        if (location.state.hasOwnProperty('travelTimeLabel')) {
            travelTimeLabelPassedIn = location.state.travelTimeLabel
        }
    }

    const fetchActivityDetail = () => {
        setIsLoadedDetail(false)
        let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/activities/" + activityId + "/"
        axios.get(url).then((response) => {
            setActivtyDetail(response.data)
            setIsLoadedDetail(true)
            document.title = response.data.name + " | Wildr"

        }).catch(error => {
            console.log(error)
            setErrorFetchingDetail(true)
            setErrorMessage(error.message)
            /*
            if (error.response.status === 404) {
                console.log("404")
              }
              console.log("Other");*/
        });
    }



    const getGovBathingWaterInfo = (govId) => {
        // This gives loads more than we use, including descriptions of sewage nearby etc
        // EG https://environment.data.gov.uk/doc/bathing-water/ukc2102-03600?_format=json&_view=basic&_format=json&_properties=latestComplianceAssessment.complianceClassification.name,eubwidNotation
        let url = `${process.env.REACT_APP_WILDR_PUBLIC_API}/bathing-water-profile-proxy/${govId}:latest?_format=json&_properties=bathingWater.yearDesignated%2CbathingWater.eubwidNotation%2CbathingWater.latestSampleAssessment.sampleDateTime.ordinalYear%2CbathingWater.latestComplianceAssessment.complianceClassification.name%2CbathingWater.latestComplianceAssessment.sampleYear.ordinalYear%2CbathingWater.latestComplianceAssessment.assessmentRegime%2CbathingWater.latestComplianceAssessment.assessmentQualifier%2CbathingWater.envelope.lowerCorner.easting%2CbathingWater.envelope.lowerCorner.northing%2CbathingWater.envelope.upperCorner.easting%2CbathingWater.envelope.upperCorner.northing%2CbathingWater.district.name%2CbathingWater.zoneOfInfluence.extent.asGML%2CbathingWater.samplingPoint.samplePointNotation%2CbathingWater.samplingPoint.easting%2CbathingWater.samplingPoint.northing%2CbathingWater.type%2CbathingWater.yearDedesignated.ordinalYear%2CbathingWater.latestOpenIncident.*%2CbathingWater.latestOpenIncident.incidentType.*%2CbathingWater.dedesignationReasonCode.*.*%2CbathingWater.dedesignationReasonText.*%2Caccount.*&_lang=en`
        axios.get(url).then((response) => {
            setGovBathingWaterProfile(response.data.result.primaryTopic.bathingWater)
        }).catch(error => {
            console.log("getGovBathingWaterInfo error", error)
        });
    }

    const getGovBathingWaterQuality = () => {
        // This get's the detail about the water samples, the range over which they were taken etc. 
        // http://environment.data.gov.uk/data/bathing-water-quality/compliance-rBWD/point/03600/year/2023
        // proxied WILDR_BAKCEND_URL/api/v1/bathing-water-quality-proxy/compliance-rBWD/point/03600/year/2023.json
        let url = govBathingWaterProfile.latestComplianceAssessment._about
        url = url.replace("http://environment.data.gov.uk/data/bathing-water-quality", `${process.env.REACT_APP_WILDR_PUBLIC_API}/bathing-water-quality-proxy`); // deal with proxy
        url = `${url}.json`
        axios.get(url).then((response) => {
            setGovWaterQaulityDetail(response.data.result.primaryTopic)
        }).catch(error => {
            console.log("getGovBathingWaterQuality error", error)
        });
    }



    React.useEffect(() => {
        fetchActivityDetail();
    }, [activityId]);

    // Get the general water info, do extra GA
    React.useEffect(() => {
        // GA stuff. We already send a pageview by default, but also send this to include more info. EG free, paid, category etc
        if (activtyDetail != null) {
            const path = location.pathname + location.search
            analytics.sendPageview(path, {
                activity_name: activtyDetail.name,
                activity_category_slug: activtyDetail.category.slug,
                activity_category_name: activtyDetail.category.name,
                activity_id: activtyDetail.id,
                activity_is_free: activtyDetail.pricing.is_free,
                activity_minimum_pounds_rounded_up: activtyDetail.pricing.minimum_pounds_rounded_up,
                activity_source: activtyDetail.source ? activtyDetail.source.slug : null,
                activity_external_image_count: activtyDetail.external_images.length,
                activity_image_count: activtyDetail.images.length,
                activity_natural_next_bookable_day: activtyDetail.natural_next_bookable_day ? activtyDetail.natural_next_bookable_day : null,
                activity_instant_booking: activtyDetail.natural_next_bookable_day ? true : null,
            })
        }


        if (activtyDetail != null && activtyDetail.source) {
            if (activtyDetail.partner_id) {
                console.log('apid', activtyDetail.partner_id)
            }
            // Water quality
            if (activtyDetail.source.slug === "gov-bathing") {
                getGovBathingWaterInfo(activtyDetail.partner_id)
            }
            // Beyonk extra info (see method notes)     
            if (activtyDetail.source && activtyDetail.source.slug === "beyonk") {
                fetchBeyonkExtraInfo()
            }
        }

    }, [activtyDetail]);

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const fetchBeyonkExtraInfo = () => {
        /*
            Ideally we would do this backend but this would require more work on the beyonk
            sync script as the endpoint we currenlty (/api/v1/adventures) use does not include the provider
            which can only be found by getting the adventure detail (/api/v1/adventures/h2793sev)
            so doing this here to keep dev quiiick
            It's a bit hacky as we do store some of this data already. 
        */
        // https://api.beyonk.com/api/v1/adventures/h2793sev

        let url = "https://api.beyonk.com/api/v1/adventures/" + activtyDetail.partner_id
        axios.get(url).then((response) => {
            setActivtyBeyonkExtraInfo(response.data)
            // console.log(response.data)
        }).catch(error => {
            console.log(error)
        });
    }

    // Get the detailed water quality report to show the span of the samples. eg Good based on 2019 to 2021 samples. 
    React.useEffect(() => {
        // console.log(govLatestComplianceAssessment)
        if (govBathingWaterProfile != null) {
            getGovBathingWaterQuality()
        }
    }, [govBathingWaterProfile]);


    React.useEffect(() => {
        if (lightboxOpenSlideIndex != null) {
            setLightboxIsOpen(true)
        }
    }, [lightboxOpenSlideIndex]);

    /*
  const iframe = document.getElementById("myIframe");
  console.log(iframe)
    */

    const beyonkSuitsLabelsMap = { dog: 'Dog Friendly', charity: 'Charity fundraising', corporate: 'Corporates', family: 'Families', batchelor: 'Hen / Stag parties', school: 'School groups' }
    const beyonkRequiredLabelsIconsMap = { warmClothing: { label: 'Warm Clothing', icon: <Beanie /> }, waterproofs: { label: 'Waterproofs', icon: <RainCoat /> }, food: { label: 'Food', icon: <Restaurant /> }, drink: { label: 'Drink', icon: <SportBottle /> }, campingEquipment: { label: 'Camping Equipment', icon: <SleepingBag /> }, other: { label: 'Other', icon: <WinterBoots /> } }
    const beyonkProvidedLabelsIconsMap = { food: { label: 'Food', icon: <Restaurant /> }, drink: { label: 'Drink', icon: <SportBottle /> }, transport: { label: 'Transport', icon: <PublicTransportation /> }, equipment: { label: 'Equipment', icon: <Carabiner /> }, other: { label: 'Other', icon: <WinterBoots /> } }


    const mergeInternalExternalImagesAddProxyAndRes = (internal, external, imagekitParams) => {
        // We add the proxy url for external images, but not internal which are already served via imagekit
        const merged = [...external.map(image => ({
            ...image,
            url: `${process.env.REACT_APP_EXTERNAL_IMAGE_PROXY_URL}${image.url}${imagekitParams}` // ?tr=h-400
        })), 
        ...internal.map(image => ({
            ...image,
            url: `${image.url}${imagekitParams}`
        }))
    ] 
    return merged
    }

    return (
        <div className='activity-details'>
            {activtyDetail &&
                <>
                    <Lightbox
                        open={isLightboxOpen}
                        thumbnails={{
                            border:0,
                          }}
                        close={() => setLightboxIsOpen(false)}
                        animation={{ fade:250, swipe:750, navigation:0 }}
                        index={lightboxOpenSlideIndex}
                        plugins={[Thumbnails, Captions, Fullscreen, Slideshow, Video, Zoom]} // Removed Thumbails for simplicity
                        slides={activtyDetail.external_images && activtyDetail.images && mergeInternalExternalImagesAddProxyAndRes(activtyDetail.images, activtyDetail.external_images, '?tr=h-900').map(slide => (
                            {
                                "src": slide.url,
                                // "title": "Slide titleasdasdasd", // Shows at top of screen, overkill, use description only
                                "description": slide.attribution && <span><a href={slide.attribution.url} target="_blank" rel="noreferrer noopener">{slide.attribution.title}</a> by <a href={slide.attribution.creator_url} target="_blank" rel="noreferrer noopener">{slide.attribution.creator}</a> is licensed under <a href={slide.attribution.license_url} target="_blank" rel="noreferrer noopener">{slide.attribution.license}</a> </span>,
                            }
                        ))}
                    />
                </>
                
            }

            <div>
                {errorFetchingDetail ?
                    <div>{errorMessage}</div>
                    :
                    <div>{isLoadedDetail ?
                        <div className='activity-detail-container' >
                            <div className='minimal-back-button-container'>
                                {props.handleCloseModal ?
                                    <div className='minimal-back-button' style={{ display: "block" }} onClick={props.handleCloseModal}><CloseButton /></div>
                                    :
                                    <div className='minimal-back-button' style={{ display: "block" }}><Link to="/search"><CloseButton /> </Link></div>
                                }
                            </div>
                            {/* 
                            This logic hides the slider options if only one image. It used to also change image sizes but was never built for mobile. 
                            */}
                            

                            <Splide options={[...activtyDetail.images, ...activtyDetail.external_images].length > 1 ?  // splide options https://splidejs.com/guides/options/
                                {
                                    height: '240px', // For some reason also need to set this in css
                                    width: '100%',
                                    autoWidth: true,
                                    gap: '12px',
                                    // borderadius: '12px', set in activity detail css instead
                                } : {
                                    height: '240px', // For some reason also need to set this in css
                                    width: '100%',
                                    autoWidth: true,
                                    gap: '12px',
                                    arrows: false,
                                    drag: false,
                                    pagination: false
                                    //type: 'loop'
                                }
                            } onClick={(xyz, slide) => { setLightboxOpenSlideIndex(slide.index) }}>

                                {/* Add a resolution limiter url param to any internal images for the preview. eg ?tr=h-240*/}
                                {
                                    [
                                        ...activtyDetail.external_images.map(slide => (
                                            <SplideSlide key={slide.url}>
                                                <img src={`${process.env.REACT_APP_EXTERNAL_IMAGE_PROXY_URL}${slide.url}?tr=h-350`} alt="" style={[...activtyDetail.external_images, ...activtyDetail.images].length > 1 ? { height: "240px" } : { height: "240px" }} />
                                            </SplideSlide>
                                        )),

                                        ...activtyDetail.images.map(slide => (
                                            <SplideSlide key={slide.url}>
                                                <img src={`${slide.url}?tr=h-350`} alt="" style={[...activtyDetail.external_images, ...activtyDetail.images].length > 1 ? { height: "240px" } : { height: "240px" }} />
                                            </SplideSlide>
                                        ))
                                    ]

                                }

                            </Splide>
                            {/* 
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <InstagramEmbed url="https://www.instagram.com/reel/Cj0ijZXoJan/" width={328} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TikTokEmbed url="https://www.tiktok.com/@aimeemacki/video/7015293348818849029" width={325} />
                                </div>
                                */}
                            <div className='activity-info'>
                                <div className="title-section left-hand">
                                    <h1>{namePassedIn ? namePassedIn : activtyDetail.name}</h1>
                                    {activtyDetail.source && activtyDetail.source.slug === "beyonk" && activtyBeyonkExtraInfo &&
                                        <h3>{activtyBeyonkExtraInfo.adventure.location.area}</h3>}
                                </div>

                                <div className="labels-section left-hand">
                                    <ActivityLabels item={activtyDetail} waterQuality={govBathingWaterProfile} />
                                </div>
                                <div className="journey-section left-hand">
                                    {travelTimePassedIn &&
                                        <JourneyTimes traveTime={travelTimePassedIn} travelTimeLabel={travelTimeLabelPassedIn ? travelTimeLabelPassedIn : 'Travel times to this activity'} />
                                    }
                                </div>
                                <div className="description-section left-hand">
                                    {activtyDetail.display_external_url_in_frontend &&
                                        <div>
                                            <p><a href={activtyDetail.external_url} target="_blank" rel="noreferrer noopener">Visit {activtyDetail.external_url} for more info.</a></p>
                                        </div>
                                    }
                                    <SocialLinks socialLinks={activtyDetail.social_links} />
                                    <ReactMarkdown>{activtyDetail.description ? activtyDetail.description : activtyDetail.short_description}</ReactMarkdown>
                                    {activtyDetail.txgb_provider &&
                                        // Remove partner_id to enable booking all providers activities
                                        <a href={"https://book.txgb.co.uk/v4/Pages/Availability.aspx?exl_dn=TestDistributor&exl_psn=" + activtyDetail.txgb_provider.short_name + "&exl_prd=" + activtyDetail.partner_id + "&exl_dc=7&exl_nts=3&exl_lng=en-GB&exl_cur=GBP"}
                                            target="_blank" rel="noreferrer noopener">Click to check availability and book!</a>

                                    }


                                    {activtyDetail.source && activtyDetail.source.slug === "gov-bathing" &&
                                        <div>
                                            {govWaterQaulityDetail && <p>The most recent Environment Agency water quality classification is '{govWaterQaulityDetail.complianceClassification.name._value}' {govWaterQaulityDetail.firstSampleDate && govWaterQaulityDetail.finalSampleDate && ", based on samples taken from " + govWaterQaulityDetail.firstSampleDate._value + " through to " + govWaterQaulityDetail.finalSampleDate._value}. </p>}
                                            {govBathingWaterProfile && <p>The most recent sample was taken on {govBathingWaterProfile.latestSampleAssessment.sampleDateTime._about.split('/').pop().split('T')[0]}</p>}
                                            <p><a href={activtyDetail.external_url} target="_blank" rel="noreferrer noopener">Click here to view the full water quality report on the Environment Agency website</a></p>
                                        </div>
                                    }
                                </div>
                                {activtyDetail.source && activtyDetail.source.slug === "beyonk" && activtyBeyonkExtraInfo &&
                                    <>

                                        {!props.hideBeyonkWidget &&
                                            <div className="widget-section">
                                                <iframe title="Beyonk Booking Form" id="myIframe" scrolling="no" src={"//sdk.beyonk.com/b/?event=" + activtyDetail.partner_id + "&partner=wildr&theme=FF7001"}></iframe>
                                            </div>
                                        }
                                        
                                        <div className="inclusions-section left-hand">
                                            {activtyBeyonkExtraInfo.adventure.provisions && (
                                                <>
                                                    {/* only show whats included if anything is */}
                                                    {Object.keys(activtyBeyonkExtraInfo.adventure.provisions.provided).filter(suit => activtyBeyonkExtraInfo.adventure.provisions.provided[suit].selected).length >= 1 &&
                                                        <div className="inclusions-list">
                                                            <h3>What's included</h3>
                                                            {
                                                                Object.entries(activtyBeyonkExtraInfo.adventure.provisions.provided).map(([key, value]) => {
                                                                    if (value && value.selected) return (
                                                                        <div className='item'>
                                                                            <div className='inclusion-icon'>{beyonkProvidedLabelsIconsMap[key].icon}</div>
                                                                            <p>
                                                                                <b>{beyonkProvidedLabelsIconsMap[key].label}</b>
                                                                                {activtyBeyonkExtraInfo.adventure.provisions.provided[key].details && <span> - {activtyBeyonkExtraInfo.adventure.provisions.provided[key].details}</span>}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                    {/* only show whats required if anything is */}
                                                    {Object.keys(activtyBeyonkExtraInfo.adventure.provisions.required).filter(suit => activtyBeyonkExtraInfo.adventure.provisions.required[suit].selected).length >= 1 &&
                                                        <div className="inclusions-list">
                                                            <h3>What you'll need</h3>
                                                            {
                                                                Object.entries(activtyBeyonkExtraInfo.adventure.provisions.required).map(([key, value]) => {
                                                                    if (value && value.selected) return (
                                                                        <div className='item'>
                                                                            <div className='inclusion-icon'>{beyonkRequiredLabelsIconsMap[key].icon}</div>
                                                                            <p>
                                                                                <b>{beyonkRequiredLabelsIconsMap[key].label}</b>
                                                                                {activtyBeyonkExtraInfo.adventure.provisions.required[key].details && <span> - {activtyBeyonkExtraInfo.adventure.provisions.required[key].details}</span>}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            )}
                                        </div>

                                        <div className="cancellations-section left-hand">
                                            <h3>Cancellation policy</h3>
                                            {activtyBeyonkExtraInfo.organisation.cancellationPolicy.type === 'flexible' &&
                                                <>
                                                    <p>
                                                        Flexible cancellation Policy:
                                                        For a full refund, cancel at least 7 days before the activity is scheduled to start.
                                                    </p>
                                                </>
                                            }
                                            {activtyBeyonkExtraInfo.organisation.cancellationPolicy.type === 'custom' &&
                                                <>
                                                    <p>Custom cancellation policy:</p>
                                                    {activtyBeyonkExtraInfo.organisation.cancellationPolicy.details &&
                                                        <div><ReactMarkdown>{activtyBeyonkExtraInfo.organisation.cancellationPolicy.details}</ReactMarkdown></div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                                <div className="attribution-section">
                                    {activtyDetail.attribution && activtyDetail.attribution.catalog_provider === "wikipedia" && !activtyDetail.attribution.modified_from_original &&
                                        <div>
                                            The description for this activity is from <a href={activtyDetail.attribution.url} target="_blank" rel="noreferrer noopener">Wikipedia.</a>
                                        </div>
                                    }
                                    {activtyDetail.attribution && activtyDetail.attribution.catalog_provider === "wikipedia" && activtyDetail.attribution.modified_from_original &&
                                        <div>
                                            The text for this activity has been modified from the original <a href={activtyDetail.attribution.url} target="_blank" rel="noreferrer noopener">Wikipedia version</a> and is released under <a href="https://creativecommons.org/licenses/by-sa/3.0/" targer="_blank" rel="noreferrer noopener">CC-BY-SA</a>
                                        </div>
                                    }
                                </div>
                                <div className="map-section">
                                    <div className="activity-details-map">
                                        <MapContainer center={[activtyDetail.location.latitude, activtyDetail.location.longitude]} zoom={13} scrollWheelZoom={false}>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Marker
                                                position={[activtyDetail.location.latitude, activtyDetail.location.longitude]}
                                                key={activtyDetail.id}
                                                icon={mapIconsSelected.hasOwnProperty(activtyDetail.category.slug) ? mapIconsSelected[activtyDetail.category.slug] : mapIconsSelected['blank']}
                                            >
                                            </Marker>
                                            {activtyDetail.gpx_path && <Polyline pathOptions={{ color: 'red' }} positions={activtyDetail.gpx_path} />}
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>


                            {activtyDetail && activtyDetail.source && activtyDetail.source.slug === "beyonk" &&
                                <>


                                    {activtyBeyonkExtraInfo &&

                                        <>


                                            <div>

                                                {activtyBeyonkExtraInfo.adventure?.suits && Object.keys(activtyBeyonkExtraInfo.adventure?.suits).filter(suit => activtyBeyonkExtraInfo.adventure.suits[suit]).length > 1 &&
                                                    <>
                                                        <div>This adventure is perfect for individuals and small groups, and can be tailored for:</div>
                                                        {
                                                            Object.entries(activtyBeyonkExtraInfo.adventure.suits).map(([key, value]) => {
                                                                if (value) return <span><DoneIcon />{beyonkSuitsLabelsMap[key]} </span>
                                                            })
                                                        }
                                                    </>
                                                }


                                            </div>
                                            <div>Tags:
                                                {
                                                    activtyBeyonkExtraInfo.adventure.tags?.map(tag => <span> {capitalizeFirstLowercaseRest(tag)} </span>)
                                                }
                                            </div>
                                            <div>Provider name: {activtyBeyonkExtraInfo?.organisation.companyName}</div>
                                            <div>Experience provider: {activtyBeyonkExtraInfo.organisation.companyName}</div>

                                        </>
                                    }



                                </>
                            }
                        </div>
                        : <div>loading...</div>
                    }
                    </div>

                }
            </div>
        </div>
    )
}

